import React from 'react'
import PortfolioBackground from '../components/portfolioBackground'
import MothButtons from '../components/MothButtons'
import PlanetContainer from '../components/planetContainer'
import SEO from '../components/seo'
import "./layout.css"

function Desktop() {
    return (
        <>
        <SEO title="Portfolio" />
        <div className="hide-under-720">
            <div className="portfolio-content">
                <PlanetContainer />
                <span style={{height: 120}} />
                <MothButtons size="small" order={[
                    {to: "/home", label: "Home"},
                    {to: "/curriculum-vitae", label:  "CV"},
                    {to: "/links", label:  "Links"},
                    {to: "/contact", label:  "Contact"},
                    ]}/>
            </div>
        </div>
        </>
    )
}

function Mobile() {
    return (
        <div className="hide-over-720">
            <div className="portfolio-content">
                <MothButtons size="small" order={[
                    {to: "/home", label: "Home"},
                    {to: "/curriculum-vitae", label:  "CV"},
                    {to: "/links", label:  "Links"},
                    {to: "/contact", label:  "Contact"},
                    ]}/>
                <PlanetContainer />
                {/* <span style={{height: 120}} /> */}
            </div>
        </div>
    )
}
export default function Portfolio() {
    return (
        <PortfolioBackground>
            <Desktop />
            <Mobile />
        </PortfolioBackground>
    )
}
