import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import BackgroundImage from 'gatsby-background-image'

export default function PortfolioBackground ({ children }) {
  const data = useStaticQuery(graphql`
  query {
      desktop: file(relativePath: { eq: "portfolio_bg.jpg" }) {
          childImageSharp {
              fluid(maxWidth: 2000, quality: 100) {
                  ...GatsbyImageSharpFluid
              }
              }
          },
      mobile: file(relativePath: { eq: "graphic-design/mobile-portfolio-bg2.jpg" }) {
          childImageSharp {
              fluid(maxWidth: 700, quality: 100) {
                  ...GatsbyImageSharpFluid
              }
              }
          }
      }
  `)
  // Set ImageData.
  const sources = [
    data.desktop.childImageSharp.fluid,
    {
      ...data.mobile.childImageSharp.fluid,
      media: `(max-width: 720px)`,
    },
  ]
  console.log(data)
  return (
    <BackgroundImage
      Tag="section"
      className="portfolio-bg-img"
      fluid={sources}
      style={{
        // Defaults are overwrite-able by setting one or each of the following:
        backgroundSize: '',
        backgroundPosition: '',
      }}
    >
    {children}
    </BackgroundImage>
  )
}