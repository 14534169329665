import React, { useState } from 'react'
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export default function PlanetContainer() {
    const data = useStaticQuery(
        graphql`
        query {
            writingsPlanet: file(relativePath: { eq: "writingplanet.png" }) {
              childImageSharp {
                fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
            },
            writingsPlanetRo: file(relativePath: { eq: "rollovers/writingplanet-ro.png" }) {
                childImageSharp {
                  fluid(maxWidth: 500) {
                      ...GatsbyImageSharpFluid
                    }
                  }
              },
            graphicDesignPlanet: file(relativePath: { eq: "GDplanet.png" }) {
                childImageSharp {
                    fluid(maxWidth: 500) {
                        ...GatsbyImageSharpFluid
                    }
                    }
                },
            graphicDesignPlanetRo: file(relativePath: { eq: "rollovers/GDplanet-ro.png" }) {
                childImageSharp {
                    fluid(maxWidth: 500) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                },
            otherPlanet: file(relativePath: { eq: "art-planet.png" }) {
                childImageSharp {
                    fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                    }
                }
            },
            otherPlanetRo: file(relativePath: { eq: "rollovers/art-planet-ro.png" }) {
                childImageSharp {
                    fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                    }
                }
            },
          }
        `
      )

    const [writingRo, toggleWritingRo] = useState(false)
    const [GDRo, toggleGDRo] = useState(false)
    const [otherArtRo, toggleotherArtRo] = useState(false)

    return (
        <div className="planet-container">
            <div className="planet-wrap writings-planet" 
            onMouseOver={()=>toggleWritingRo(true)}
            onMouseLeave={()=>toggleWritingRo(false)}
            >
                <Link to="/writing">
                    {writingRo ? (
                        <Img className="planet-img" fluid={data.writingsPlanetRo.childImageSharp.fluid} loading={"eager"} />
                        ) : (
                        <Img className="planet-img" fluid={data.writingsPlanet.childImageSharp.fluid} />
                    )}
                    <h2 className="planet-heading">Writings</h2>
                </Link>
            </div>
            <div className="planet-wrap gd-planet"
            onMouseOver={()=>toggleGDRo(true)}
            onMouseLeave={()=>toggleGDRo(false)}
            >
                <Link to="/graphic-design">
                    {GDRo ? (
                        <Img className="planet-img" fluid={data.graphicDesignPlanetRo.childImageSharp.fluid} loading={"eager"} />
                    ) : (
                        <Img className="planet-img" fluid={data.graphicDesignPlanet.childImageSharp.fluid} />
                    )}
                    <h2 className="planet-heading">Graphic Design</h2>
                </Link>
            </div>
                <div className="planet-wrap other-planet"
                onMouseOver={()=>toggleotherArtRo(true)}
                onMouseLeave={()=>toggleotherArtRo(false)}
                >
                    <Link to="/other-art">
                        {otherArtRo ? (
                            <Img className="planet-img" fluid={data.otherPlanetRo.childImageSharp.fluid} loading={"eager"} />
                        ) : (
                            <Img className="planet-img" fluid={data.otherPlanet.childImageSharp.fluid} />
                        )}
                        <h2 className="planet-heading">Other Art</h2>
                    </Link>
                </div>
                <div className="preloaded-gd-images" id="artPlanetRo" />
                <div className="preloaded-gd-images" id="gdPlanetRo" />
                <div className="preloaded-gd-images" id="writingPlanetRo" />
        </div>
    )
}
